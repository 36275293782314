<template>
    <div>
        <div class="board-view">
            <div class="board-view__row board-view__row--title">
                {{ board.subject }}
            </div>
            <div class="board-view__row board-view__row--info">
                <v-row>
                    <v-col cols="auto">
                        작성자 : 관리자
                    </v-col>
                    <v-col cols="auto">
                        작성일 : {{ $dayjs(board.createdAt).format("YYYY-MM-DD HH:mm:ss") }}
                    </v-col>
                    <v-col cols="auto">
                        조회수 : {{ board.viewCount }}
                    </v-col>
                </v-row>
            </div>
            <div v-html="board.content" class="board-view__row board-view__row">
            </div>
        </div>
        <div class="mt-24px">
            <v-row justify="end">
                <v-col cols="auto">
                    <btn-tertiary x-small @click="$router.go(-1)">리스트</btn-tertiary>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import api from "@/api"
import BtnTertiary from "@/components/publish/parents/buttons/btn-tertiary.vue";
export default {
    components: {
        BtnTertiary,
    },
    async mounted() {
        await this.init();
    },
    data() {
        return {
            board: {}
        }
    },
    methods: {
        async init() {
            let { board } = await api.v1.boards.get({ _id: this.$route.params._board });
            this.board = board;
        }
    }
}
</script>

<style>

</style>