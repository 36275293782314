var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.topLine ? _c('v-divider', {
    style: _vm.dark ? 'border-color: rgba(255, 255, 255, .2)' : ''
  }) : _vm._e(), _c('div', {
    staticClass: "pa-20px pa-md-30px px-lg-80px py-lg-60px"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "mb-12px mb-md-0",
    attrs: {
      "data-aos": "fade-up",
      "cols": "12",
      "md": "6"
    }
  }, [_c('tit', {
    class: _vm.dark ? 'white--text' : ''
  }, [_vm._v(_vm._s(_vm.title))])], 1), _c('v-col', {
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "100",
      "cols": "12",
      "md": "6"
    }
  }, [_c('txt', {
    class: _vm.dark ? 'white--text' : 'txt--dark'
  }, [_vm._t("default")], 2)], 1)], 1)], 1), _c('v-divider', {
    style: _vm.dark ? 'border-color: rgba(255, 255, 255, .2)' : ''
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }