<template>
    <v-row no-gutters>
        <v-col cols="auto" class="pr-4px">
            ·
        </v-col>
        <v-col>
            <slot />
        </v-col>
    </v-row>
</template>

<script>
export default {

}
</script>

<style>

</style>